import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

type langTranslationStrings = Array<{ id: string, text: string }>;

interface ILangTranslation {
  lang: string;
  translations: langTranslationStrings;
}

type allTranslations = Array<ILangTranslation>;

@Injectable({providedIn: 'root'})
export class LocalizationService {

  private _addedTranslations: allTranslations = [];

  constructor(private _translateService: TranslateService) {
    this._translateService.onLangChange.subscribe((tr: ILangTranslation) => {
      this.checkAddedTranslations(tr.lang);
    });
  }

  translate(key: string | Array<string>, interpolateParams?: object): string | any {
    return this._translateService.instant(key, interpolateParams);
  }

  get(key: string | Array<string>, interpolateParams?: object): Observable<string | any> {
    return this._translateService.get(key, interpolateParams);
  }

  getTranslations(lang: string): Observable<any> {
    return this._translateService.getTranslation(lang).pipe(
      tap(() => {
        this.checkAddedTranslations(lang);
      })
    );
  }

  translateToLang(lang: string, key: string, interpolateParams?: object, nullValue?: string) {
    const currentTranslations = this.getCurrentTranslations(lang);
    return (currentTranslations && currentTranslations[key]) || nullValue === undefined ? this.getParsedResult(currentTranslations, key, interpolateParams) : (nullValue ? this.getParsedResult(currentTranslations, nullValue, interpolateParams) : null);
  }

  getCurrentTranslations(lang: string): any {
    const addedStrings = this._addedTranslations.find(a => a.lang === lang);
    return {
      ...this._translateService.translations[lang],
      ...this.getTranslationsKeyValueDictionary(addedStrings ? addedStrings.translations : null)
    };
  }

  getParsedResult(translations: any, key: any, interpolateParams?: object): any {
    return this._translateService.getParsedResult(translations, key, interpolateParams);
  }

  addTranslations(lang: string, translations: langTranslationStrings) {
    if (this._translateService.translations[lang]) {
      this.mergeTranslationsWithExisting(lang, translations);
    } else {
      // app locations for this lang was not loaded yet -> store added translations when loaded
      let existingAddedTrans = this._addedTranslations.find(t => t.lang === lang);
      if (!existingAddedTrans) {
        existingAddedTrans = {lang, translations};
        this._addedTranslations.push(existingAddedTrans);
      } else {
        translations.forEach(t => {
          const existingString = existingAddedTrans.translations.find(s => s.id === t.id);
          if (existingString) {
            existingString.text = t.text;
          } else {
            existingAddedTrans.translations.push({...t});
          }
        });

      }
    }
  }

  private mergeTranslationsWithExisting(lang: string, translations: langTranslationStrings) {
    const ngxTrans = this.getTranslationsKeyValueDictionary(translations);
    // if lang already loaded add new translation with merge flag
    this._translateService.setTranslation(lang, ngxTrans, true);
  }

  private getTranslationsKeyValueDictionary(translations: langTranslationStrings): { [key: string]: string } {
    return translations ? translations.reduce((map: any, obj: any) => {
      map[obj.id] = obj.text;
      return map;
    }, {}) : {};
  }

  private checkAddedTranslations(lang: string) {
    // check if there are waiting some additional translations loaded before app translations
    this._addedTranslations = this._addedTranslations.filter(addedTr => {
      if (addedTr.lang === lang) {
        this.mergeTranslationsWithExisting(addedTr.lang, addedTr.translations);
        return false;
      } else {
        return true;
      }
    });
  }
}
