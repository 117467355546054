import { LocalizationService } from './services/localizationService';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef, Injectable, Pipe, PipeTransform } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';

interface TransPipeOptions {
  _lang_: string; // explicit language
  _keyPrefix_: string; // key prefix - used to detect if query is a key or value
  _nullValue_: string; // text to display if value is null
}

@Injectable()
@Pipe({
  name: 'trans',
  pure: false // required to update the value when the promise is resolved
})
export class TransPipe extends TranslatePipe implements PipeTransform {
  constructor(private _translate: TranslateService, private cdr: ChangeDetectorRef, private _localizationService: LocalizationService) {
    super(_translate, cdr);
  }

  transform(query: string, ...args: any[]): any {
    if (query && args && args.length > 0) {
      const options = args[0] as TransPipeOptions;
      if (options && options._keyPrefix_) {
        if (query.indexOf(options._keyPrefix_) !== 0) {
          // query contains the value not the key
          return query;
        }
      }
      if (options && options._lang_) {
        return this._localizationService.translateToLang(options._lang_, query, args, options._nullValue_);
      }
    }
    return super.transform(query, ...args);
  }
}
