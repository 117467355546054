import { Observable, of, Observer } from 'rxjs';

import { TranslateLoader } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

export class TranslatePOLoader implements TranslateLoader {

  constructor(private loader: (lang: string) => Observable<any>) {
  }

  getTranslation(lang: string): Observable<any> {
    return this.loader(lang).pipe(
      map((poTranslations) => {
        // po-loader returns keys with separator '\u0004'. Transform keys to use '|' separator
        const translations = {};
        for (const property in poTranslations) {
          if (poTranslations.hasOwnProperty(property)) {
            const key = property.replace('\u0004', '|');
            translations[key] = poTranslations[property];
          }
        }
        return translations;
      })
    );
  }
}
